import "../../style/sidemenu-pages-style/EPDTable.css";
import React, { useState, useEffect } from "react";
import config from "../../config";
import Grid from "../../environment-page-components/Grid";
import Plot from "react-plotly.js";
import EPDPlot from "./EPDPlot";

function EPDTable({
  isStandardSuppliersLibrary,
  setGwpValues,
  setIsBoxplotView,
  calculateStandardDeviation,
  setFirstSelectedRow,
}) {
  const [leedCategories, setLeedCategories] = useState([]);
  const [subCategories, setSubCategories] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [isExpanded, setIsExpanded] = useState(true);
  const [epdInfo, setEpdInfo] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [plantGeography, setPlantGeography] = useState([]);
  const [selectedManufacturer, setSelectedManufacturer] = useState("");
  const [selectedPlantGeography, setSelectedPlantGeography] = useState("");
  const [selectedRows, setSelectedRows] = useState([]); // Added to track selected rows
  const [isBoxplotView, setIsBoxplotViewState] = useState(false);

  // Column definitions for the ag-Grid
  const columnDefs = [
    {
      headerName: "Category",
      field: "Category",
      checkboxSelection: true, // Added checkbox for each row
      headerCheckboxSelection: true, // Added "Select All" checkbox in the header
    },
    { headerName: "Manufacturer", field: "Manufacturer" },
    { headerName: "Plant or Group", field: "Plant or Group" },
    { headerName: "Name", field: "Name" },
    { headerName: "Description", field: "Description" },
    { headerName: "Program Operator", field: "Program Operator" },
    { headerName: "Verifier Org", field: "Verifier Org" },
    { headerName: "Plant Geography", field: "Plant Geography" },
    { headerName: "Declared Unit", field: "Declared Unit" },
    { headerName: "GWP", field: "GWP" },
    { headerName: "Updated On", field: "Updated On" },
  ];

  // Fetch LEED categories from the backend
  const fetchLeedCategories = () => {
    fetch(`${config.apiUrl}/fetch_data`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        table_name: "leed_categories",
        attribute: "*",
        condition: "1",
      }),
    })
      .then((response) => {
        if (!response.ok)
          throw new Error(
            "Network response was not ok: " + response.statusText
          );
        return response.json();
      })
      .then((data) => {
        const categories = data.map((item) => item.category);
        const subCatMap = {};
        data.forEach((item) => {
          subCatMap[item.category] = JSON.parse(item.sub_category);
        });
        setLeedCategories(categories);
        setSubCategories(subCatMap);
      })
      .catch((error) =>
        console.error("Error fetching LEED categories:", error)
      );
  };

  // Fetch EPD info from the backend and dynamically update manufacturers and plant groups
  const fetchEpdInfo = async (subcategory) => {
    if (!subcategory) {
      setEpdInfo([]);
      setManufacturers([]);
      setPlantGeography([]);
      return;
    }

    try {
      const response = await fetch(`${config.apiUrl}/fetch_data`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          table_name: "EPD_info",
          attribute: "*",
          condition: `Category='${subcategory}'`,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok: " + response.statusText);
      }

      const data = await response.json();
      setEpdInfo(data);

      // Extract unique manufacturers and plant groups dynamically
      const uniqueManufacturers = [
        ...new Set(data.map((item) => item.Manufacturer)),
      ].filter((item) => item); // Filter out null or undefined values

      const uniquePlantGeography = [
        ...new Set(data.map((item) => item["Plant Geography"])),
      ].filter((item) => item);

      setManufacturers(uniqueManufacturers);
      setPlantGeography(uniquePlantGeography);

      // Filter data based on selected manufacturer and plant group
      const filteredData = data.filter((item) => {
        return (
          (!selectedManufacturer ||
            item.Manufacturer === selectedManufacturer) &&
          (!selectedPlantGeography ||
            item["Plant Geography"] === selectedPlantGeography)
        );
      });

      setEpdInfo(filteredData);
    } catch (error) {
      console.error("Error fetching EPD info:", error);
    }
  };

  useEffect(() => {
    fetchLeedCategories();
  }, []);

  useEffect(() => {
    fetchEpdInfo(selectedSubCategory);
  }, [selectedSubCategory, selectedManufacturer, selectedPlantGeography]);

  useEffect(() => {
    setGwpValues(getGwpValues());
  }, [epdInfo, selectedRows]);

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setSelectedSubCategory("");
    setEpdInfo([]); // Clear EPD info when category changes
  };

  const handleSubCategoryChange = (e) => {
    setSelectedSubCategory(e.target.value);
  };

  const handleManufacturerChange = (e) => {
    setSelectedManufacturer(e.target.value);
  };

  const handlePlantGeographyChange = (e) => {
    setSelectedPlantGeography(e.target.value);
  };

  const toggleContainer = () => {
    setIsExpanded(!isExpanded);
  };

  // Handle selection changes in the grid
  const onSelectionChanged = (event) => {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    setSelectedRows(selectedData);
    setFirstSelectedRow(selectedData[0]);
    console.log("Selected Rows:", selectedData);
  };

  // Extract GWP values for the boxplot
  const getGwpValues = () => {
    const dataSource = selectedRows.length > 0 ? selectedRows : epdInfo;
    return dataSource
      .map((item) => parseFloat(item.GWP))
      .filter((value) => !isNaN(value));
  };

  const gwpValues = getGwpValues();

  const gwpMin = Math.min(...gwpValues);
  const gwpMax = Math.max(...gwpValues);
  const gwpMean =
    gwpValues.reduce((sum, value) => sum + value, 0) / gwpValues.length || 0;

  const gwpStdDev = calculateStandardDeviation(gwpValues);

  const handleCheckboxChange = () => {
    setIsBoxplotViewState(!isBoxplotView);
    setIsBoxplotView(!isBoxplotView);
  };

  return (
    <div
      className="epd-table-container"
      style={{
        backgroundColor: "#faf4e8",
      }}
    >
      <div className="container-header-process">
        <h2
          onClick={toggleContainer}
          onMouseEnter={() =>
            (document.querySelector(
              ".container-header-process h2"
            ).style.color = "grey")
          }
          onMouseLeave={() =>
            (document.querySelector(
              ".container-header-process h2"
            ).style.color = "black")
          }
          style={{ cursor: "pointer" }}
        >
          EPD Table
        </h2>
      </div>
      {isExpanded && (
        <div className="epd-details">
          <div className="dropdown-container">
            <div className="dropdown">
              <label htmlFor="leed-category">LEED Category:</label>
              <select
                id="leed-category"
                className="custom-select__control-epd"
                value={selectedCategory}
                onChange={handleCategoryChange}
              >
                <option value="" disabled>
                  Select a category
                </option>
                {leedCategories.map((category) => (
                  <option
                    key={category}
                    value={category}
                    className="custom-select__option"
                  >
                    {category}
                  </option>
                ))}
              </select>
            </div>
            <div className="dropdown">
              <label htmlFor="sub-category">Subcategory:</label>
              <select
                id="sub-category"
                className="custom-select__control-epd"
                value={selectedSubCategory}
                onChange={handleSubCategoryChange}
              >
                <option value="" disabled>
                  Select a subcategory
                </option>
                {subCategories[selectedCategory]?.map((subCategory) => (
                  <option
                    key={subCategory}
                    value={subCategory}
                    className="custom-select__option"
                  >
                    {subCategory}
                  </option>
                ))}
              </select>
            </div>
            <div className="dropdown">
              <label htmlFor="manufacturer-select">Manufacturer</label>
              <select
                id="manufacturer-select"
                className="custom-select__control-epd"
                value={selectedManufacturer}
                onChange={handleManufacturerChange}
              >
                <option value="">Select Manufacturer</option>
                {manufacturers.map((manufacturer, index) => (
                  <option key={index} value={manufacturer}>
                    {manufacturer}
                  </option>
                ))}
              </select>
            </div>
            <div className="dropdown">
              <label htmlFor="plant-geography-select">Plant Geography</label>
              <select
                id="plant-geography-select"
                className="custom-select__control-epd"
                value={selectedPlantGeography}
                onChange={handlePlantGeographyChange}
              >
                <option value="">Select Plant Geography</option>
                {plantGeography.map((plantGeography, index) => (
                  <option key={index} value={plantGeography}>
                    {plantGeography}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="epd-info-table">
            <Grid
              columnDefs={columnDefs}
              rowData={epdInfo}
              rowSelection="multiple" // Enable multiple row selection
              onSelectionChanged={onSelectionChanged} // Track selected rows
            />
            <div className="gwp-stats">
              <p>
                Min: {gwpMin.toFixed(2)}&nbsp;&nbsp; |&nbsp;&nbsp; Max:{" "}
                {gwpMax.toFixed(2)}&nbsp;&nbsp; |&nbsp;&nbsp; Mean:{" "}
                {gwpMean.toFixed(2)}&nbsp;&nbsp; |&nbsp;&nbsp; Standard
                Deviation: {gwpStdDev.toFixed(2)}
              </p>
            </div>
            <div className="checkbox-container-bottom">
              <label>
                <input
                  type="checkbox"
                  checked={isBoxplotView}
                  onChange={handleCheckboxChange}
                />
                Boxplot View
              </label>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EPDTable;

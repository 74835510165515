import React, {useEffect, useState, useRef} from 'react';
import {Bar} from 'react-chartjs-2';
import {Icon} from '@iconify/react';  // Importing the Icon component
import '../style/components-style/TrackView.css';  // Ensure this file includes the CSS rules mentioned

function TrackView({pageType, selectedRows}) {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [{
            label: 'GWP Total (kg CO₂e)',
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderWidth: 1
        }]
    });

    const [costPerGWPData, setCostPerGWPData] = useState({
        labels: [],
        datasets: [{
            label: 'Cost Per GWP ($/kg CO₂e)',
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderWidth: 1
        }]
    });
    const [totalGWP, setTotalGWP] = useState(0.0);
    const [totalCost, setTotalCost] = useState(0.0);

    const [isOpen, setIsOpen] = useState(true);

    const predefinedColors = [
        'rgba(75, 192, 192, 0.6)', 'rgba(255, 99, 132, 0.6)', 'rgba(54, 162, 235, 0.6)',
        'rgba(255, 206, 86, 0.6)', 'rgba(153, 102, 255, 0.6)', 'rgba(255, 159, 64, 0.6)',
        'rgba(199, 199, 199, 0.6)', 'rgba(83, 102, 255, 0.6)', 'rgba(255, 102, 204, 0.6)',
        'rgba(102, 255, 102, 0.6)'
    ];

    const predefinedBorderColors = predefinedColors.map(color => color.replace('0.6', '1'));

    const chartRef = useRef(null);
    const costPerGWPRef = useRef(null);

    useEffect(() => {
        const labels = selectedRows.map(row => row.product || row.design_installation || row.organization);
        const gwpData = selectedRows.map(row => row.gwp_total);
        const costData = selectedRows.map(row => parseFloat(row.cost));
        const costPerGWPDataValues = selectedRows.map(row => {
            const cost = row.cost;
            const gwp = row.gwp_total;
            return gwp > 0 ? (cost / gwp).toFixed(2) : 'N/A';
        });
        const quantityData = selectedRows.map(row => row.quantity);
    
        const colors = predefinedColors.slice(0, selectedRows.length);
        const borderColors = predefinedBorderColors.slice(0, selectedRows.length);

        let multipliedGwpData, multipliedCostPerGWPDataValues;
        if (pageType === 'valueChain' || pageType === 'organization' || pageType === 'corporate') {
            multipliedGwpData = gwpData.map((value) => value.toFixed(2));
            multipliedCostPerGWPDataValues = costPerGWPDataValues.map((value, index) => {
                const numericValue = parseFloat(value);
                return !isNaN(numericValue) ? numericValue.toFixed(2) : 'N/A';
            });
        } else {
            const quantityData = selectedRows.map(row => row.quantity);

            multipliedGwpData = gwpData.map((value, index) => (value * quantityData[index]).toFixed(2));
            multipliedCostPerGWPDataValues = costPerGWPDataValues.map((value, index) => {
                const numericValue = parseFloat(value);
                return !isNaN(numericValue) ? (numericValue * quantityData[index]).toFixed(2) : 'N/A';
            });
        }

        setTotalGWP(multipliedGwpData.reduce((acc, value) => acc + parseFloat(value), 0));
        setTotalCost(costData.reduce((acc, value) => acc + value, 0));

        setChartData({
            labels,
            datasets: [{
                label: 'GWP Total (kg CO₂e)',
                data: multipliedGwpData,
                backgroundColor: colors,
                borderColor: borderColors,
                borderWidth: 1
            }]
        });
        
        setCostPerGWPData({
            labels,
            datasets: [{
                label: 'Cost Per GWP ($/kg CO₂e)',
                data: multipliedCostPerGWPDataValues,
                backgroundColor: colors,
                borderColor: borderColors,
                borderWidth: 1
            }]
        });
    }, [selectedRows]);

    const options = {
        scales: {
            y: {
                beginAtZero: true
            }
        },
        plugins: {
            legend: {
                display: true,
                labels: {
                    boxWidth: 0,
                    generateLabels: (chart) => {
                        return [{
                            text: '',
                            fillStyle: 'rgba(0, 0, 0, 0)',
                            hidden: false,
                            lineCap: 'butt',
                            lineDash: [],
                            lineDashOffset: 0,
                            lineJoin: 'miter',
                            lineWidth: 0,
                            strokeStyle: 'rgba(0, 0, 0, 0)',
                            pointStyle: 'rectRot',
                            textAlign: 'left'
                        }];
                    }
                }
            }
        }
    };

    const costPerGWPOptions = {
        scales: {
            y: {
                beginAtZero: true
            }
        },
        plugins: {
            legend: {
                display: true,
                labels: {
                    boxWidth: 0,
                    generateLabels: (chart) => {
                        return [{
                            text: '',
                            fillStyle: 'rgba(0, 0, 0, 0)',
                            hidden: false,
                            lineCap: 'butt',
                            lineDash: [],
                            lineDashOffset: 0,
                            lineJoin: 'miter',
                            lineWidth: 0,
                            strokeStyle: 'rgba(0, 0, 0, 0)',
                            pointStyle: 'rectRot',
                            textAlign: 'left'
                        }];
                    }
                }
            }
        }
    };

    // Function to download chart as image
    const downloadChartAsImage = (ref, filename) => {
        const chart = ref.current;
        if (chart) {
            const url = chart.toBase64Image();
            const link = document.createElement('a');
            link.href = url;
            link.download = `${filename}.png`;
            link.click();
        }
    };

    return (
        <div className="track-view-container">
            {isOpen && (
                <div className="charts-container">
                    {/* GWP Total Chart */}
                    <div className="chart-wrapper reuse-graph-container">
                        <div className="chart-summary">
                            <p>Total GWP: <strong>{totalGWP.toFixed(2)} kg CO₂e</strong></p>
                        </div>
                        <div className="download-icon"
                             onClick={() => downloadChartAsImage(chartRef, 'gwp_total_graph')}>
                            <Icon icon="fe:download" style={{color: 'grey'}}/>
                        </div>
                        <h3>GWP Total (kg CO₂e)</h3>
                        <Bar
                            ref={chartRef}
                            data={chartData}
                            options={options}
                        />
                    </div>

                    {/* Cost Per GWP Chart */}
                    <div className="chart-wrapper reuse-graph-container">
                        <div className="chart-summary">
                            <p>Total Cost: <strong>${totalCost.toFixed(2)} $</strong></p>
                        </div>
                        <div className="download-icon"
                             onClick={() => downloadChartAsImage(costPerGWPRef, 'cost_per_gwp_graph')}>
                            <Icon icon="fe:download" style={{color: 'grey'}}/>
                        </div>
                        <h3>Cost Per GWP ($/kg CO₂e)</h3>
                        <Bar
                            ref={costPerGWPRef}
                            data={costPerGWPData}
                            options={costPerGWPOptions}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default TrackView;

import React from "react";
import environmentIcon from "@iconify-icons/mdi/environment";
import organizationIcon from "@iconify-icons/mdi/human-queue";
import zeroBoxIcon from "@iconify-icons/mdi/numeric-zero-box-multiple-outline";
import leafCircleOutlineIcon from "@iconify-icons/mdi/leaf-circle-outline";
import truckIcon from "@iconify-icons/mdi/truck";
import HomePageTab from "../homepage-components/HomePageTab";
import "../style/pages-style/HomePage.css";
import Header from "../app_components/Header";
import { translations } from "../app_components/Translation";

function HomeViewType({ selectedLanguage, currentVersion, setCurrentVersion }) {
  const selectedText = translations[selectedLanguage];

  const allFeatures = [
    {
      icon: environmentIcon,
      title: selectedText.product,
      description: selectedText.description,
      link: "/home",
      showIn: "abc",
    },
    {
      icon: truckIcon,
      title: selectedText.fleetPerformanceTitle,
      description: selectedText.description,
      link: "/fleet/performance",
      showIn: "flots",
    },
    {
      icon: organizationIcon,
      title: selectedText.organizational,
      description: selectedText.description,
      link: "/homeorganizational",
      showIn: "flots",
    },
    {
      icon: zeroBoxIcon,
      title: selectedText.netZeroTitle,
      description: selectedText.description,
      link: "/netzero",
      showIn: "flots",
    },
  ];

  const features = allFeatures.filter((feature) => feature.showIn === currentVersion);

  return (
    <>
      <div className="home-page">
        <Header selectedLanguage={selectedLanguage} />
        {features.map((feature, index) => (
          <HomePageTab key={index} icon={feature.icon} title={feature.title} description={feature.description} link={feature.link} />
        ))}
      </div>
    </>
  );
}

export default HomeViewType;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../style/pages-style/TermsAndConditions.css';
import { useAuth } from '../authentication/AuthProvider';
import { usePendingUser } from '../authentication/PendingUserContext';
import mammoth from 'mammoth';
import TermsEnglish from '../assets/ARIVU_RUMKER_TermsofUse_and_Services.docx';
import TermsFrench from '../assets/ARIVU_RUMKER_TermsofUse_and_Services FR.docx';
import TermsEnglishAlt from '../assets/ARIVU_ASF_TermsofUse_and_Services.docx';
import TermsFrenchAlt from '../assets/ARIVU_ASF_TermsofUse_and_Services fr.docx';

const TermsAndConditions = ({ selectedLanguage }) => {
    const navigate = useNavigate();
    const auth = useAuth();
    const { pendingUser, setPendingUser, setTermsAccepted } = usePendingUser();
    const [docxContent, setDocxContent] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const storedUsername = localStorage.getItem('username');

    useEffect(() => {
        const loadDocx = async () => {
            try {
                const selectedFile = storedUsername === 'rumker'
                    ? (selectedLanguage === 'FR' ? TermsFrench : TermsEnglish)
                    : (selectedLanguage === 'FR' ? TermsFrenchAlt : TermsEnglishAlt);
                const response = await fetch(selectedFile);
                const arrayBuffer = await response.arrayBuffer();
                const { value } = await mammoth.extractRawText({ arrayBuffer });
                setDocxContent(value);
            } catch (error) {
                setErrorMessage('Failed to load terms and conditions.');
            }
        };

        loadDocx();
    }, [selectedLanguage, storedUsername]);

    const handleAccept = () => {
        if (!isChecked) {
            setErrorMessage(
                selectedLanguage === 'FR'
                    ? "Vous devez accepter les termes et conditions avant de continuer."
                    : "You must accept the terms and conditions before proceeding."
            );
            return;
        }
        if (pendingUser) {
            auth.login({ username: pendingUser });
            setPendingUser(null);
            setTermsAccepted(true);
            localStorage.setItem('termsAccepted', 'true');
        }
        navigate('/homeviewtype');
    };

    const handleDecline = () => {
        setPendingUser(null);
        auth.logout();
        setTermsAccepted(false);
        localStorage.setItem('termsAccepted', 'false');
        navigate('/');
    };

    return (
        <div className="terms-container">
            <h1 className="terms-header">
                {selectedLanguage === 'FR' ? 'Conditions d’utilisation et de service' : 'Terms and Conditions'}
            </h1>
            <div className="terms-content">
                {docxContent ? (
                    <div dangerouslySetInnerHTML={{ __html: docxContent.replace(/\n/g, '<br>') }} />
                ) : (
                    <p>Loading terms and conditions...</p>
                )}
            </div>
            <div className="checkbox-container">
                <label>
                    <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={(e) => {
                            setIsChecked(e.target.checked);
                            setErrorMessage('');
                        }}
                    />
                    {selectedLanguage === 'FR'
                        ? "J'ai lu et j'accepte les termes et conditions."
                        : "I have read and accept the terms and conditions."}
                </label>
            </div>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <div className="terms-buttons">
                <button onClick={handleDecline} className="button-logout">
                    {selectedLanguage === 'FR' ? 'Refuser' : 'Decline'}
                </button>
                <button onClick={handleAccept} className="button-login">
                    {selectedLanguage === 'FR' ? 'Accepter' : 'Accept'}
                </button>
            </div>
        </div>
    );
};

export default TermsAndConditions;

import React, {useEffect, useRef, useState} from 'react';
import '../style/pages-style/NetZero.css';
import Header from '../app_components/Header';
import {translations} from "../app_components/Translation";
import TableComponent from "../environment-page-components/Table";
import Grid from "../environment-page-components/Grid";
import ScopeEmissionGraph from "../net-zero-page-components/ScopeEmissionGraph";
import config from "../config";
import netZeroTableHeaderComponent from "../net-zero-page-components/netZeroTableHeaderComponent";
import {Icon} from "@iconify/react";

function NetZero({selectedLanguage}) {
    const selectedText = translations[selectedLanguage].netZero;

    const fixedScopes = [
        "Scope 1",
        "Scope 2 (location-based)",
        "Scope 2 (market-based)",
        "Scope 3, category 1: Purchased goods and services",
        "Scope 3, category 2: Capital goods",
        "Scope 3, category 3: Fuel- and energy-related activities",
        "Scope 3, category 4: Upstream transportation and distribution",
        "Scope 3, category 5: Waste generated in operations",
        "Scope 3, category 6: Business travel",
        "Scope 3, category 7: Employee commuting",
        "Scope 3, category 8: Upstream leased assets",
        "Scope 3, category 9: Downstream transportation and distribution",
        "Scope 3, category 10: Processing of sold products",
        "Scope 3, category 11: Use of sold products",
        "Scope 3, category 12: End-of-life treatment of sold products",
        "Scope 3, category 13: Downstream leased assets",
        "Scope 3, category 14: Franchises",
        "Scope 3, category 15: Investments",
        "Total: scope 1, scope 2 market-based, scope 3 (allcategories)"
    ];

    const initialColumnDefs = [
        {
            headerName: "Scope",
            field: "scope",
            sortable: true,
            filter: true,
            checkboxSelection: true,
            headerCheckboxSelection: true,
        },
        {
            headerName: "Based Year Emissions(tCO2e)",
            field: "column1",
            sortable: true,
            filter: true,
            valueFormatter: (params) => formatFloatValue(params.value, 2),
            headerComponent: netZeroTableHeaderComponent,
            headerComponentParams: {
                onAddButtonClick: () => handleHeaderButtonClick('column1'),
                onDeleteButtonClick: () => handleDeleteColumnClick('column1'),
            },
        },
    ];

    const initialRowData = fixedScopes.map((scope) => ({
        scope,
        column1: 0, // Initialize the first year to 0
    }));

    const [valueChainColumnDefs, setValueChainColumnDefs] = useState(initialColumnDefs);
    const valueChainColumnDefsRef = useRef(valueChainColumnDefs);
    const [organizationColumnDefs, setOrganizationColumnDefs] = useState(initialColumnDefs);
    const organizationColumnDefsRef = useRef(organizationColumnDefs);
    const [corporateColumnDefs, setCorporateColumnDefs] = useState(initialColumnDefs);
    const corporateColumnDefsRef = useRef(corporateColumnDefs);
    const [valueChainRowData, setValueChainRowData] = useState(initialRowData);
    const valueChainRowDataRef = useRef(valueChainRowData);
    const [organizationRowData, setOrganizationRowData] = useState(initialRowData);
    const organizationRowDataRef = useRef(organizationRowData);
    const [corporateRowData, setCorporateRowData] = useState(initialRowData);
    const corporateRowDataRef = useRef(corporateRowData);
    const [selectedRowData, setSelectedRowData] = useState([]);
    const selectedRowDataRef = useRef(selectedRowData);
    const [mainTableGridApi, setMainTableGridApi] = useState(null);
    const [trackViewState, setTrackViewState] = useState(false);
    const [allTableRows, setAllTableRows] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const [pageType, setPageType] = useState('valueChain');
    const pageTypeRef = useRef(pageType);
    const [isOpen, setIsOpen] = useState(true);
    const netZeroPage = true;
    const [selectedScope, setSelectedScope] = useState([]);

    useEffect(() => {
        selectedRowDataRef.current = selectedRowData;
    }, [selectedRowData]);

    useEffect(() => {
        valueChainColumnDefsRef.current = valueChainColumnDefs;
    }, [valueChainColumnDefs]);

    useEffect(() => {
        organizationColumnDefsRef.current = organizationColumnDefs;
    }, [organizationColumnDefs]);

    useEffect(() => {
        corporateColumnDefsRef.current = corporateColumnDefs;
    }, [corporateColumnDefs]);

    useEffect(() => {
        valueChainRowDataRef.current = valueChainRowData;
    }, [valueChainRowData]);

    useEffect(() => {
        organizationRowDataRef.current = organizationRowData;
    }, [organizationRowData]);

    useEffect(() => {
        corporateRowDataRef.current = corporateRowData;
    }, [corporateRowData]);

    useEffect(() => {
        pageTypeRef.current = pageType
    }, [pageType]);

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    const handleHeaderButtonClick = async (field) => {
        try {
            const currentSelectedRowData = selectedRowDataRef.current;
            if (!currentSelectedRowData || currentSelectedRowData.length === 0) {
                return;
            }
            const response = await fetch(`${config.apiUrl}/api/get-yearly-based-scope-emissions?row_id=${currentSelectedRowData[0].id}`);
            const data = await response.json();

            // Check if the fetched data contains the correct structure
            if (!Array.isArray(data)) {
                console.error('Invalid data format from API');
                return;
            }

            // Update rowData with the fetched values
            if (pageTypeRef.current === 'valueChain') {
                const updatedRowData = valueChainRowDataRef.current.map((row, index) => ({
                    ...row,
                    [field]: index < data.length ? data[index].value : 0,
                }));
                setValueChainRowData(updatedRowData);
            } else if (pageTypeRef.current === 'organization') {
                const updatedRowData = organizationRowDataRef.current.map((row, index) => ({
                    ...row,
                    [field]: index < data.length ? data[index].value : 0,
                }));
                setOrganizationRowData(updatedRowData);
            } else if (pageTypeRef.current === 'corporate') {
                const updatedRowData = corporateRowDataRef.current.map((row, index) => ({
                    ...row,
                    [field]: index < data.length ? data[index].value : 0,
                }));
                setCorporateRowData(updatedRowData);
            } else {
                console.warn('Unknown page type')
            }


            if (pageTypeRef.current === 'valueChain') {
                const updatedColumnDefs = valueChainColumnDefsRef.current.map((colDef, index) => {
                    if (colDef.field === field) {
                        if (index === 1) {
                            return {
                                ...colDef,
                                headerName: `Based Year Emissions(tCO2e) - ${currentSelectedRowData[0].Year}`,
                            };
                        } else {
                            return {
                                ...colDef,
                                headerName: `Yearly Emissions(tCO2e) - ${currentSelectedRowData[0].Year}`,
                            };
                        }
                    }
                    return colDef;
                });
                setValueChainColumnDefs(updatedColumnDefs)
            } else if (pageTypeRef.current === 'organization') {
                const updatedColumnDefs = organizationColumnDefsRef.current.map((colDef, index) => {
                    if (colDef.field === field) {
                        if (index === 1) {
                            return {
                                ...colDef,
                                headerName: `Based Year Emissions(tCO2e) - ${currentSelectedRowData[0].Year}`,
                            };
                        } else {
                            return {
                                ...colDef,
                                headerName: `Yearly Emissions(tCO2e) - ${currentSelectedRowData[0].Year}`,
                            };
                        }
                    }
                    return colDef;
                });
                setOrganizationColumnDefs(updatedColumnDefs)
            } else if (pageTypeRef.current === 'corporate') {
                const updatedColumnDefs = corporateColumnDefsRef.current.map((colDef, index) => {
                    if (colDef.field === field) {
                        if (index === 1) {
                            return {
                                ...colDef,
                                headerName: `Based Year Emissions(tCO2e) - ${currentSelectedRowData[0].Year}`,
                            };
                        } else {
                            return {
                                ...colDef,
                                headerName: `Yearly Emissions(tCO2e) - ${currentSelectedRowData[0].Year}`,
                            };
                        }
                    }
                    return colDef;
                });
                setCorporateColumnDefs(updatedColumnDefs)
            } else {
                console.warn('Unknown page type')
            }


        } catch (error) {
            console.error('Error fetching data from backend:', error);
        }
    };

    const handleDeleteColumnClick = (field) => {
        if (pageTypeRef.current === 'valueChain') {
            const updatedColumnDefs = valueChainColumnDefsRef.current.filter((colDef) => colDef.field !== field);
            setValueChainColumnDefs(updatedColumnDefs);
        } else if (pageTypeRef.current === 'organization') {
            const updatedColumnDefs = organizationColumnDefsRef.current.filter((colDef) => colDef.field !== field);
            setOrganizationColumnDefs(updatedColumnDefs);
        } else if (pageTypeRef.current === 'corporate') {
            const updatedColumnDefs = corporateColumnDefsRef.current.filter((colDef) => colDef.field !== field);
            setCorporateColumnDefs(updatedColumnDefs);
        } else {
            console.warn('Unknown page type')
        }


        if (pageTypeRef.current === 'valueChain') {
            const updatedRowData = valueChainRowDataRef.current.map((row) => {
                const {[field]: _, ...rest} = row;
                return rest;
            });
            setValueChainRowData(updatedRowData);
        } else if (pageTypeRef.current === 'organization') {
            const updatedRowData = organizationRowDataRef.current.map((row) => {
                const {[field]: _, ...rest} = row;
                return rest;
            });
            setOrganizationRowData(updatedRowData);
        } else if (pageTypeRef.current === 'corporate') {
            const updatedRowData = corporateRowDataRef.current.map((row) => {
                const {[field]: _, ...rest} = row;
                return rest;
            });
            setCorporateRowData(updatedRowData);
        } else {
            console.warn('Unknown page type')
        }
    };

    const toggleContainer = () => setIsOpen(!isOpen);

    function formatFloatValue(value, decimalPlaces) {
        return parseFloat(value).toFixed(decimalPlaces);
    }

    const addColumn = () => {
        if (pageTypeRef.current === 'valueChain') {
            const newFieldName = `column${valueChainColumnDefs.length}`;
            const newColumn = {
                headerName: valueChainColumnDefs.length === 1 ? `Base Year Emissions(tCO2e)` : `Yearly Emissions(tCO2e)`,
                field: newFieldName,
                sortable: true,
                filter: true,
                valueFormatter: (params) => formatFloatValue(params.value, 2),
                headerComponent: netZeroTableHeaderComponent,
                headerComponentParams: {
                    onAddButtonClick: () => handleHeaderButtonClick(newFieldName),
                    onDeleteButtonClick: () => handleDeleteColumnClick(newFieldName),
                },
            };

            // Update column definitions
            const updatedColumnDefs = [...valueChainColumnDefsRef.current, newColumn];
            setValueChainColumnDefs(updatedColumnDefs);

            // Update row data to include the new column with value 0
            const updatedRowData = valueChainRowData.map((row) => ({
                ...row,
                [newFieldName]: 0,
            }));
            setValueChainRowData(updatedRowData);
        } else if (pageTypeRef.current === 'organization') {
            const newFieldName = `column${organizationColumnDefs.length}`;
            const newColumn = {
                headerName: organizationColumnDefs.length === 1 ? `Base Year Emissions(tCO2e)` : `Yearly Emissions(tCO2e)`,
                field: newFieldName,
                sortable: true,
                filter: true,
                valueFormatter: (params) => formatFloatValue(params.value, 2),
                headerComponent: netZeroTableHeaderComponent,
                headerComponentParams: {
                    onAddButtonClick: () => handleHeaderButtonClick(newFieldName),
                    onDeleteButtonClick: () => handleDeleteColumnClick(newFieldName),
                },
            };

            // Update column definitions
            const updatedColumnDefs = [...organizationColumnDefsRef.current, newColumn];
            setOrganizationColumnDefs(updatedColumnDefs);

            // Update row data to include the new column with value 0
            const updatedRowData = organizationRowData.map((row) => ({
                ...row,
                [newFieldName]: 0,
            }));
            setOrganizationRowData(updatedRowData);
        } else if (pageTypeRef.current === 'corporate') {
            const newFieldName = `column${corporateColumnDefs.length}`;
            const newColumn = {
                headerName: corporateColumnDefs.length === 1 ? `Base Year Emissions(tCO2e)` : `Yearly Emissions(tCO2e)`,
                field: newFieldName,
                sortable: true,
                filter: true,
                valueFormatter: (params) => formatFloatValue(params.value, 2),
                headerComponent: netZeroTableHeaderComponent,
                headerComponentParams: {
                    onAddButtonClick: () => handleHeaderButtonClick(newFieldName),
                    onDeleteButtonClick: () => handleDeleteColumnClick(newFieldName),
                },
            };

            // Update column definitions
            const updatedColumnDefs = [...corporateColumnDefsRef.current, newColumn];
            setCorporateColumnDefs(updatedColumnDefs);

            // Update row data to include the new column with value 0
            const updatedRowData = corporateRowData.map((row) => ({
                ...row,
                [newFieldName]: 0,
            }));
            setCorporateRowData(updatedRowData);
        } else {
            console.warn('Unknown page type')
        }
    }

    const onColumnMovedHandler = () => {
        if (gridApi && gridColumnApi) {
            // Get all columns in their new order
            const allColumns = gridColumnApi.getAllDisplayedColumns();

            // Map the updated column definitions in the new order
            const newColumnDefs = allColumns.map((column) => column.getColDef());

            // Update the column definitions for the appropriate pageType
            if (pageType === 'valueChain') {
                setValueChainColumnDefs(newColumnDefs);
            } else if (pageType === 'organization') {
                setOrganizationColumnDefs(newColumnDefs);
            } else if (pageType === 'corporate') {
                setCorporateColumnDefs(newColumnDefs);
            }

            // Get the column order by their field IDs
            const columnOrder = allColumns.map((col) => col.getColId());

            // Reorder the selected rows based on the new column order
            const selectedRows = gridApi.getSelectedRows();
            const reorderedSelectedRows = selectedRows.map((row) => {
                const reorderedRow = {};
                columnOrder.forEach((field) => {
                    // Retain only the fields in the new column order
                    if (row.hasOwnProperty(field)) {
                        reorderedRow[field] = row[field];
                    }
                });
                return reorderedRow;
            });

            // Log reordered rows for debugging
            console.log('Reordered Selected Rows:', reorderedSelectedRows);

            // Update the state for selected rows in the new order
            setSelectedScope(reorderedSelectedRows);
        }
    };

    return (
        <div className="supplier-utilizer">
            <div className="netZero-page">
                <Header selectedLanguage={selectedLanguage}/>
                <div className={`main-table-container-circularity ${netZeroPage ? 'circularity-active' : ''}`}>
                    <TableComponent
                        setSelectedRowData={setSelectedRowData}
                        setMainTableGridApi={setMainTableGridApi}
                        setTrackViewState={setTrackViewState}
                        selectedLanguage={selectedLanguage}
                        setAllTableRows={setAllTableRows}
                        childTableRows={[]}
                        pageType={pageType}
                        setPageType={setPageType}
                        netZeroPage={netZeroPage}
                    />

                    <div className="material-container">
                        <div className="container-header-material">
                            <h2
                                onClick={toggleContainer}
                                onMouseEnter={() => document.querySelector('.container-header-material h2').style.color = 'grey'}  // Hover color
                                onMouseLeave={() => document.querySelector('.container-header-material h2').style.color = 'black'} // Default color
                                style={{cursor: 'pointer'}}
                            >
                                {selectedText.ghgTitle}
                            </h2>
                        </div>

                        {isOpen && (
                            <div>
                                <button className="add-column-button" title="Add a new column for yearly emissions"
                                        onClick={addColumn}><Icon icon="material-symbols:add-column-right-outline"/>
                                </button>
                                <Grid
                                    rowData={pageType === 'valueChain' ? valueChainRowData : (pageType === 'organization' ? organizationRowData : corporateRowData)}
                                    columnDefs={pageType === 'valueChain' ? valueChainColumnDefs : (pageType === 'organization' ? organizationColumnDefs : corporateColumnDefs)}
                                    rowSelection="multiple"
                                    onGridReady={onGridReady}
                                    onSelectionChanged={(event) => setSelectedScope(event.api.getSelectedRows())}
                                    onColumnMoved={onColumnMovedHandler}
                                />
                            </div>
                        )}
                    </div>

                </div>
                <ScopeEmissionGraph selectedText={selectedText} scopeEmissionData={selectedScope}
                                    columnDefs={pageType === 'valueChain' ? valueChainColumnDefs : (pageType === 'organization' ? organizationColumnDefs : corporateColumnDefs)}/>
            </div>
        </div>
    );
}

export default NetZero;

const ScopeTableGWPHeaderComponent = ({displayName, setUnit, unit}) => {
    const toggleUnit = () => {
        setUnit((prevUnit) => (prevUnit === 'kg' ? 'ton' : 'kg')); // Toggle the unit
    };

    return (
        <span style={{
            cursor: 'pointer',       // Make the header clickable
            textAlign: 'right',      // Align the text to the right
            width: '100%',           // Ensure it spans the full width
            paddingRight: '10px',    // Add padding for spacing
        }} onClick={toggleUnit}>
            {displayName}
        </span>
    );
};

export default ScopeTableGWPHeaderComponent;
import React, {useMemo, useState} from "react";
import {Bar, Line} from 'react-chartjs-2';
import {CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Tooltip} from 'chart.js';
import regression from 'regression';
import '../style/components-style/ScopeEmissionGraph.css'

// Register required Chart.js components
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Legend, Tooltip);


function ScopeEmissionGraph({selectedText, scopeEmissionData, columnDefs}) {
    const [isOpen, setIsOpen] = useState(true);
    const [showRegression, setShowRegression] = useState(false);
    const [predictionYear, setPredictionYear] = useState('');
    const [predictedValue, setPredictedValue] = useState(null);
    const [regressionGraphData, setRegressionGraphData] = useState(null);
    const [predictedGraphData, setPredictedGraphData] = useState(null);
    const [regressionType, setRegressionType] = useState('polynomial');
    const [differenceMode, setDifferenceMode] = useState('baseYear');
    const toggleContainer = () => setIsOpen(!isOpen);

    const labels = columnDefs
        .filter((colDef) => colDef.field !== 'scope') // Exclude the 'scope' field
        .map((colDef) => colDef.headerName);

    const originalGraphData = {
        labels: labels,
        datasets: scopeEmissionData.map((entry) => ({
            label: entry.scope,
            data: columnDefs
                .filter((colDef) => colDef.field !== 'scope')
                .map((colDef) => entry[colDef.field] || 0),
            borderColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
                Math.random() * 255
            )}, 1)`, // Random color
            backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
                Math.random() * 255
            )}, 0.2)`,
            fill: false,
        })),
    };

    const handlePrediction = () => {
        if (predictionYear === null || predictionYear === undefined) return;

        const regressionDataset = {...regressionGraphData.datasets[0]};
        const dataLength = originalGraphData.labels.length;
        const targetYear = predictionYear;

        if (targetYear < dataLength) {
            alert('Prediction year must be greater than the last known data year.');
            return;
        }

        const newPredictedData = [];
        const newLabels = [];
        let confidenceInterval = null;

        for (let year = dataLength; year <= targetYear; year++) {
            let predictedValue;

            if (regressionType === 'linear') {
                // Extract slope and intercept for linear regression
                const xValues = Array.from({length: originalGraphData.labels.length}, (_, i) => i); // Indices as x-values
                const yValues = originalGraphData.datasets[0].data;

                const n = xValues.length;
                const meanX = xValues.reduce((a, b) => a + b, 0) / n;

                const sumX = xValues.reduce((a, b) => a + b, 0);
                const sumY = yValues.reduce((a, b) => a + b, 0);
                const sumXY = xValues.reduce((sum, x, i) => sum + x * yValues[i], 0);
                const sumX2 = xValues.reduce((sum, x) => sum + x * x, 0);

                const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX);
                const intercept = (sumY - slope * sumX) / n;

                predictedValue = slope * year + intercept;

                // Compute confidence interval only for the last year
                if (year === targetYear) {
                    // Calculate residuals for RSE
                    const residuals = yValues.map((y, i) => y - (slope * xValues[i] + intercept));

                    // Compute Residual Standard Error (RSE)
                    const rse = Math.sqrt(residuals.reduce((sum, r) => sum + r * r, 0) / (residuals.length - 2));

                    // Compute Standard Error (SE)
                    const sumXDiffSquared = xValues.reduce((sum, x) => sum + Math.pow(x - meanX, 2), 0);
                    const se = rse * Math.sqrt(1 + 1 / xValues.length + Math.pow(year - meanX, 2) / sumXDiffSquared);

                    // Compute 95% Confidence Interval
                    const tValue = 1.96; // Approximation for 95% confidence
                    const lowerBound = parseFloat((predictedValue - tValue * se).toFixed(2));
                    const upperBound = parseFloat((predictedValue + tValue * se).toFixed(2));

                    confidenceInterval = {lower: lowerBound, upper: upperBound};
                }
            } else if (regressionType === 'polynomial') {
                const coefficients = regressionDataset.label
                    .match(/y = (.+)/)[1]
                    .split(' + ')
                    .reverse()
                    .map((term) => parseFloat(term.match(/-?\d+(\.\d+)?/)[0]));

                // Predict using the polynomial equation: y = a_n*x^n + ... + a_0
                predictedValue = coefficients.reduce((sum, coef, power) => sum + coef * Math.pow(year, power), 0);
            }

            predictedValue = parseFloat(predictedValue.toFixed(2));

            newPredictedData.push(predictedValue);
            newLabels.push(`Year ${year}`);
        }

        const combinedData = {
            ...regressionGraphData,
            labels: [...regressionGraphData.labels, ...newLabels],
            datasets: regressionGraphData.datasets.map((dataset) =>
                dataset.label === regressionDataset.label
                    ? {...dataset, data: [...dataset.data, ...newPredictedData]} // Clone dataset for predicted data
                    : dataset
            ),
        };

        // Update only predicted graph data
        setPredictedGraphData(combinedData);

        // Update the last predicted value in the state
        setPredictedValue({
            value: newPredictedData[newPredictedData.length - 1].toFixed(2),
            confidenceInterval,
        });
    };

    useMemo(() => {
        const data = {
            labels: columnDefs
                .filter((colDef) => colDef.field !== 'scope')
                .map((colDef) => colDef.headerName),
            datasets: scopeEmissionData.map((entry) => {
                const dataPoints = columnDefs
                    .filter((colDef) => colDef.field !== 'scope')
                    .map((colDef, index) => [index, entry[colDef.field] || 0]);

                let result;
                let equation;
                if (regressionType === 'linear') {
                    result = regression.linear(dataPoints);
                    equation = `${result.equation[1].toFixed(2)}x + ${result.equation[0].toFixed(2)}`;
                } else if (regressionType === 'polynomial') {
                    result = regression.polynomial(dataPoints, {order: 3});
                    const coefficients = result.equation;
                    equation = coefficients
                        .map((coef, i) => (i === 0 ? coef.toFixed(2) : `${coef.toFixed(2)}x^${i}`))
                        .reverse()
                        .join(" + ");
                }

                return {
                    label: `${entry.scope} (Regression Curve: y = ${equation})`,
                    data: result.points.map(([, y]) => y),
                    borderColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
                        Math.random() * 255
                    )}, 1)`,
                    backgroundColor: 'transparent',
                    borderDash: [5, 5],
                    fill: false,
                    pointRadius: 0,
                    tension: regressionType === 'linear' ? 0 : 0.4,
                };
            }),
        };

        setRegressionGraphData(data);
        setPredictedGraphData(data)
    }, [scopeEmissionData, columnDefs, regressionType]);

    const graphOptions = {
        responsive: true,
        maintainAspectRatio: true, // Disable aspect ratio to customize the size
        interaction: {
            mode: 'index', // Interact with the line index-wise (hover along the x-axis)
            intersect: false // Enable hover effect on the line, not just points
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Years',
                    font: {
                        size: 16 // Adjust the font size
                    }
                }
            },
            y: {
                title: {
                    display: true,
                    text: 'Scope Emissions (tCO₂e)',
                    font: {
                        size: 16 // Adjust the font size
                    }
                }
            }
        },
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    font: {
                        size: 14 // Increase legend font size
                    }
                }
            },
            tooltip: {
                enabled: true,
                mode: 'index', // Tooltip will show for all datasets on the hovered x-axis
                intersect: false, // Trigger tooltip when hovering near the line
                callbacks: {
                    label: function (context) {
                        const index = context.dataIndex; // Index of the current hovered point
                        const dataset = context.dataset; // Current dataset
                        const currentValue = dataset.data[index]; // Current point value
                        const previousValue = index > 0 ? dataset.data[index - 1] : null; // Previous point value

                        // Calculate percentage difference if previous value exists
                        let percentageDiff = '';
                        if (previousValue !== null && previousValue !== 0) {
                            const diff = ((currentValue - previousValue) / previousValue) * 100;
                            percentageDiff = ` (${diff.toFixed(2)}%)`;
                        }

                        // Default tooltip label with percentage difference
                        return `${dataset.label}: ${currentValue}${percentageDiff}`;
                    }
                },
                bodyFont: {
                    size: 14 // Adjust tooltip font size
                }
            }
        }
    };

    const percentageDifferenceData = useMemo(() => {
        const updatedLabels = columnDefs
            .filter((colDef) => colDef.field !== 'scope') // Exclude 'scope' field
            .map((colDef) => colDef.headerName)
            .slice(1); // Exclude the first year for percentage differences

        const updatedDatasets = scopeEmissionData.map((entry, entryIndex) => {
            const percentages = columnDefs
                .filter((colDef) => colDef.field !== 'scope')
                .map((colDef, index) => {
                    if (index === 0) return null; // Skip the first entry (no comparison for base year or previous year)

                    const currentValue = entry[colDef.field] || 0;

                    let referenceValue;
                    if (differenceMode === 'baseYear') {
                        // Compare with the base year (first year)
                        referenceValue = entry[columnDefs[1]?.field] || 0; // First year's data
                    } else if (differenceMode === 'previousYear' && index > 0) {
                        // Compare with the previous year
                        referenceValue = entry[columnDefs[index]?.field] || 0;
                    }

                    if (referenceValue === 0) return 0; // Avoid division by zero
                    return ((currentValue - referenceValue) / referenceValue) * 100;
                })
                .slice(1); // Remove the first null

            return {
                label: `${entry.scope} (Percentage Difference)`,
                data: percentages,
                backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
                    Math.random() * 255
                )}, 0.5)`,
            };
        });

        return {
            labels: updatedLabels,
            datasets: updatedDatasets,
        };
    }, [columnDefs, scopeEmissionData, differenceMode]);


    const percentageDifferenceOptions = {
        responsive: true,
        maintainAspectRatio: true, // Ensures the chart resizes with the container
        animation: {
            duration: 500, // Smooth animation duration
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Years',
                    font: {
                        size: 14, // Adjust font size for better readability
                    },
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Percentage Change (%)',
                    font: {
                        size: 14, // Adjust font size for better readability
                    },
                },
                ticks: {
                    callback: (value) => `${value}%`, // Add percentage symbol to y-axis labels
                },
            },
        },
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    font: {
                        size: 12, // Legend font size
                    },
                },
            },
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => `${tooltipItem.dataset.label}: ${tooltipItem.raw.toFixed(2)}%`,
                },
            },
            datalabels: {
                display: true,
                formatter: (value) => `${value.toFixed(2)}%`, // Format bar labels
                anchor: 'end',
                align: 'top',
                font: {
                    size: 12,
                },
            },
        },
    };


    return (
        <div className="scope-emission-graph">
            <div className="scope-emission-graph-header">
                <h2
                    onClick={toggleContainer}
                    onMouseEnter={() => document.querySelector('.scope-emission-graph-header h2').style.color = 'grey'}
                    onMouseLeave={() => document.querySelector('.scope-emission-graph-header h2').style.color = 'black'}
                    style={{cursor: 'pointer'}}
                >
                    {selectedText.ghgGraphTitle || 'Emission Graph'}
                </h2>
            </div>
            <div className="selection-container">
                <label htmlFor="graph-type">Choose Graph Type: </label>
                <select
                    id="graph-type"
                    value={showRegression ? 'regression' : 'original'}
                    onChange={(e) => setShowRegression(e.target.value === 'regression')}
                >
                    <option value="original">Original Graph</option>
                    <option value="regression">Regression Graph</option>
                </select>
            </div>
            {showRegression && (
                <div className="selection-container">
                    <label htmlFor="regression-type">Choose Regression Type: </label>
                    <select
                        id="regression-type"
                        value={regressionType}
                        onChange={(e) => setRegressionType(e.target.value)}
                        style={{marginRight: '10px'}}
                    >
                        <option value="linear">Linear Regression</option>
                        <option value="polynomial">Polynomial Regression</option>
                    </select>
                </div>
            )}
            {showRegression && (<div className="prediction-container">
                <label htmlFor="predict-year">Targeted Term: </label>
                <input
                    type="number"
                    id="predict-year"
                    value={predictionYear}
                    onChange={(e) => setPredictionYear(parseInt(e.target.value) || '')}
                    placeholder="Enter Year"
                    style={{marginRight: '10px'}}
                />
                <label>Years </label>
                <button onClick={handlePrediction}>Predict</button>
            </div>)}
            {predictedValue !== null && showRegression && (
                <div className="prediction-result">
                    <p>
                        Predicted Emissions in {predictionYear} years: <strong>{predictedValue.value}</strong>
                    </p>
                    {predictedValue.confidenceInterval && (
                        <p>
                            Confidence Interval (95%):{' '}
                            <strong>[{predictedValue.confidenceInterval.lower}, {predictedValue.confidenceInterval.upper}]</strong>
                        </p>
                    )}
                </div>
            )}
            {isOpen && (
                <>
                    <Line
                        data={showRegression ? predictedGraphData : originalGraphData}
                        options={graphOptions}
                    />
                    <div className="bar-chart-container">
                        <h3>Yearly Percentage Difference</h3>
                        <div className="selection-container">
                            <label htmlFor="difference-mode">Percentage Difference Mode: </label>
                            <select
                                id="difference-mode"
                                value={differenceMode}
                                onChange={(e) => setDifferenceMode(e.target.value)}
                                style={{marginRight: '10px'}}
                            >
                                <option value="baseYear">Based on the Base Year</option>
                                <option value="previousYear">Based on the Previous Year</option>
                            </select>
                        </div>
                        <Bar data={percentageDifferenceData} options={percentageDifferenceOptions}/>
                    </div>
                </>
            )}
        </div>
    )
}

export default ScopeEmissionGraph;
import "../style/components-style/DocumentViewer.css"
import {useEffect, useState} from "react";
import config from "../config";

const fixed_scopes = [
    "Overview Documents",
    "Scope 1",
    "Scope 2 (location-based)",
    "Scope 2 (market-based)",
    "Scope 3, category 1: Purchased goods and services",
    "Scope 3, category 2: Capital goods",
    "Scope 3, category 3: Fuel- and energy-related activities",
    "Scope 3, category 4: Upstream transportation and distribution",
    "Scope 3, category 5: Waste generated in operations",
    "Scope 3, category 6: Business travel",
    "Scope 3, category 7: Employee commuting",
    "Scope 3, category 8: Upstream leased assets",
    "Scope 3, category 9: Downstream transportation and distribution",
    "Scope 3, category 10: Processing of sold products",
    "Scope 3, category 11: Use of sold products",
    "Scope 3, category 12: End-of-life treatment of sold products",
    "Scope 3, category 13: Downstream leased assets",
    "Scope 3, category 14: Franchises",
    "Scope 3, category 15: Investments",
]

function DocumentViewer({pageType, selectedRow}) {
    const [documents, setDocuments] = useState({});
    const [selectedDocuments, setSelectedDocuments] = useState({});

    const handleFileUpload = (event, scope) => {
        const selectedFiles = Array.from(event.target.files); // Convert FileList to an array

        if (selectedFiles.length === 0) {
            alert("No files selected.");
            return;
        }

        const formData = new FormData();
        formData.append("rowID", selectedRow?.id || "unknown"); // Row ID associated with the upload
        formData.append("scope", scope); // Append scope to the FormData
        if (pageType === 'valueChain') {
            formData.append("IDColumnName", "product_service_id")
        } else if (pageType === 'organization') {
            formData.append("IDColumnName", "organization_id")
        } else if (pageType === 'corporate') {
            formData.append("IDColumnName", "corporate_id")
        } else {
            console.warn("Invalid page type");
            return;
        }

        // Append all selected files
        for (let i = 0; i < selectedFiles.length; i++) {
            formData.append("files", selectedFiles[i]);
        }

        // Send the files and scope to the backend API
        fetch(`${config.apiUrl}/api/upload_emission_reference_files`, {
            method: "POST",
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("Upload success:", data);
                alert("Files uploaded successfully!");
                fetchFiles(selectedRow.id)
            })
            .catch((error) => {
                console.error("Upload error:", error);
                alert("Failed to upload files.");
            });
    };

    const fetchFiles = (ID) => {
        fetch(`${config.apiUrl}/api/fetch_emission_reference_files?ID=${ID}`)
            .then((response) => response.json())
            .then((data) => {
                // Group files by scope
                const groupedFiles = data.reduce((acc, file) => {
                    if (!acc[file.scope]) acc[file.scope] = []; // Initialize scope group
                    acc[file.scope].push({name: file.name, url: file.url, id: file.id});
                    return acc;
                }, {});
                setDocuments(groupedFiles); // Update state with retrieved files
            })
            .catch((error) => console.error("Fetch error:", error));
    };

    useEffect(() => {
        if (selectedRow) {
            fetchFiles(selectedRow.id);
        }
    }, [selectedRow]);

    const handleSelectDocument = (scope, index) => {
        setSelectedDocuments((prevSelected) => {
            const updatedSelected = {...prevSelected};
            const documentList = documents[scope] || [];
            const currentDocument = documentList[index]; // Get file details

            // Ensure we create a new Set for immutability
            const currentSet = new Set(updatedSelected[scope] || []);

            // Convert to JSON string for Set comparison
            const documentKey = JSON.stringify({
                file_id: currentDocument.id,
                file_url: currentDocument.url,
            });

            if (currentSet.has(documentKey)) {
                currentSet.delete(documentKey); // Uncheck
            } else {
                currentSet.add(documentKey); // Check
            }

            updatedSelected[scope] = currentSet;
            return updatedSelected;
        });
    };

    const handleDeleteSelected = (scope) => {
        console.log(documents)
        console.log(selectedDocuments)
        const selectedFiles = Array.from(selectedDocuments[scope] || []).map((document) =>
            JSON.parse(document) // Parse the JSON string back to an object
        );

        if (selectedFiles.length === 0) {
            alert("No files selected for deletion.");
            return;
        }

        // Prepare data to send to the backend
        const fileIds = selectedFiles.map((file) => file.file_id);
        const fileUrls = selectedFiles.map((file) => file.file_url);

        // Call the delete API
        fetch(`${config.apiUrl}/api/delete_emission_reference_files`, {
            method: "DELETE",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                file_ids: fileIds,
                file_urls: fileUrls,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("Delete response:", data);
                fetchFiles(selectedRow.id)
                setSelectedDocuments((prevSelected) => {
                    const updatedSelected = {...prevSelected};
                    updatedSelected[scope] = new Set();
                    return updatedSelected;
                });
                alert("Selected files deleted successfully!");
            })
            .catch((error) => {
                console.error("Delete error:", error);
                alert("Failed to delete selected files.");
            });
    };

    return (
        <div className="document-viewer-container">
            <table className="document-table">
                <thead>
                <tr>
                    <th>Scope</th>
                    <th>References</th>
                </tr>
                </thead>
                <tbody>
                {fixed_scopes.map((scope, index) => (
                    <tr key={index}>
                        <td>{scope}</td>
                        <td>
                            {/* File Upload Input */}
                            <input
                                type="file"
                                id={`file-upload-${index}`}
                                style={{display: "none"}}
                                multiple
                                onChange={(event) => handleFileUpload(event, scope)}
                            />
                            <button
                                onClick={() =>
                                    document
                                        .getElementById(`file-upload-${index}`)
                                        .click()
                                }
                                className="document-upload-button"
                            >
                                Upload
                            </button>

                            {/* List of Uploaded Documents */}
                            <ul className="references-list">
                                {(documents[scope] || []).map((doc, docIndex) => (
                                    <li key={docIndex}>
                                        <input
                                            type="checkbox"
                                            onChange={() =>
                                                handleSelectDocument(scope, docIndex)
                                            }
                                            checked={
                                                selectedDocuments[scope]?.has(
                                                    JSON.stringify({file_id: doc.id, file_url: doc.url})
                                                ) || false
                                            }
                                        />
                                        <a
                                            href={doc.url}
                                            download={doc.name}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="document-link"
                                        >
                                            {doc.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>

                            {/* Delete Button */}
                            <button
                                onClick={() => handleDeleteSelected(scope)}
                                className="document-delete-button"
                                disabled={
                                    !selectedDocuments[scope] ||
                                    selectedDocuments[scope].size === 0
                                }
                            >
                                Delete Selected
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

export default DocumentViewer;
import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import {useDropzone} from "react-dropzone";
import "../style/components-style/DocumentUploadModal.css";
import config from "../config";

const DocumentUploadModal = ({isOpen, onClose, selectedEmission}) => {
    const {getRootProps, getInputProps, acceptedFiles} = useDropzone();
    const [uploadedFiles, setUploadedFiles] = useState([]); // Store uploaded files

    // Fetch files for the selected emission
    const fetchFiles = (emissionID) => {
        fetch(`${config.apiUrl}/api/fetch_emission_reference_files?emissionID=${emissionID}`)
            .then((response) => response.json())
            .then((data) => {
                setUploadedFiles(data); // Update state with retrieved files
            })
            .catch((error) => console.error("Fetch error:", error));
    };

    useEffect(() => {
        if (selectedEmission) {
            fetchFiles(selectedEmission.id);
        }
    }, [selectedEmission]);

    const handleFileUpload = () => {
        if (!selectedEmission) {
            alert("No emission selected. Please select an emission.");
            return;
        }

        if (acceptedFiles.length === 0) {
            alert("Please select a file to upload.");
            return;
        }

        const formData = new FormData();
        formData.append("file", acceptedFiles[0]);
        formData.append("emissionID", selectedEmission.id);

        fetch(`${config.apiUrl}/api/upload_emission_reference_file`, {
            method: "POST",
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                alert("File uploaded successfully!");
                fetchFiles(selectedEmission.id); // Refresh the file list
            })
            .catch((error) => {
                console.error("Upload error:", error);
                alert("Failed to upload file.");
            });
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            className="modal-content"
            overlayClassName="modal-overlay"
            ariaHideApp={false}
        >
            <h3>Upload Document for Emission: {selectedEmission ? selectedEmission.category : "None Selected"}</h3>
            {selectedEmission ? (
                <>
                    <div {...getRootProps()} className="dropzone">
                        <input {...getInputProps()} />
                        <p>Drag and drop a file here, or click to select a file.</p>
                    </div>
                    {acceptedFiles.length > 0 && (
                        <div>
                            <h4>Selected File:</h4>
                            <ul>
                                {acceptedFiles.map((file, index) => (
                                    <li key={index}>{file.name}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                    <div className="modal-buttons">
                        <button className="upload-button" onClick={handleFileUpload}>
                            Upload
                        </button>
                        <button className="cancel-button" onClick={onClose}>
                            Cancel
                        </button>
                    </div>
                    {uploadedFiles.length > 0 && (
                        <div>
                            <h4>Uploaded Files for {"None"}:</h4>
                            <ul>
                                {uploadedFiles.map((file, index) => (
                                    <li key={index}>
                                        {file.name} -{" "}
                                        <a
                                            href={file.url}
                                            download={file.name}
                                            style={{color: "blue", textDecoration: "underline"}}
                                        >
                                            Download
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </>
            ) : (
                <p style={{color: "red"}}>
                    Please select an emission to upload files.
                </p>
            )}
        </Modal>
    );
};

export default DocumentUploadModal;
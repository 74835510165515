import React, {memo, useEffect, useState} from "react";
import "../style/components-style/ProcessEnvFactorButton.css";
import config from "../config";
import {translations} from "../app_components/Translation";
import {Icon} from "@iconify/react";
import DocumentUploadModal from "./DocumentUploadModal";

function ScopeTableButton({
                              selectedRow,
                              selectedScopes = [],
                              scopeRowData,
                              tableGridApi,
                              product,
                              organization,
                              corporate,
                              selectedTab,
                              refreshData,
                              selectedLanguage,
                              triggerRefresh
                          }) {
    const [disableSave, setDisableSave] = useState(true);
    const selectedText = translations[selectedLanguage].scopeTable;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [allTableRows, setAllTableRows] = useState([]);

    const DisplayDocumentWindow = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const onCellValueChanged = (event) => {
        setDisableSave(false);
    };

    useEffect(() => {
        if (tableGridApi) {
            tableGridApi.addEventListener("cellValueChanged", onCellValueChanged);
        }

        return () => {
            if (tableGridApi) {
                tableGridApi.removeEventListener("cellValueChanged", onCellValueChanged);
            }
        };
    }, [tableGridApi]);

    useEffect(() => {
        setAllTableRows(scopeRowData);
    }, [scopeRowData]);

    const createNewEmissionData = () => {
        let newData;
        if (product) {
            newData = {
                id: undefined,
                category: "",
                description: "",
                organization_id: null,
                product_service_id: product["id"],
                corporate_id: null,
            };
        } else if (organization) {
            newData = {
                id: undefined,
                category: "",
                description: "",
                organization_id: organization["id"],
                product_service_id: null,
                corporate_id: null,
            };
        } else if (corporate) {
            newData = {
                id: undefined,
                category: "",
                description: "",
                organization_id: null,
                product_service_id: null,
                corporate_id: corporate["id"],
            };
        }
        console.log(selectedTab);
        console.log(selectedText);
        newData.is_avoided = selectedTab === selectedText.avoided ? 1 : 0;
        return newData;
    };

    const addRow = () => {
        if (!tableGridApi) return;
        const newRow = createNewEmissionData()
        tableGridApi.applyTransaction({add: [newRow]});
        setAllTableRows((prevRows) => [...prevRows, newRow]);
        setDisableSave(false);
    };

    const deleteSelectedRow = () => {
        if (!tableGridApi || selectedScopes.length === 0) return;

        const selectedIds = selectedScopes.map((row) => row.id);
        const tableName = "emissions";

        const rowsToDelete = selectedScopes.map((row) => ({
            gwp: row.gwp,
            parent_table_name: product ? 'products_services' : (organization ? 'organizations' : (corporate ? 'corporates' : null)),
            parent_id: product ? row.product_service_id : (organization ? row.organization_id : (corporate ? row.corporate_id : null)),
        }));

        fetch(`${config.apiUrl}/delete_row`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({table_name: tableName, selected_ids: selectedIds}),
        })
            .then((response) => response.json())
            .then((data) => {
                updateParentGwpTotals(rowsToDelete);
                refreshData();
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const updateParentGwpTotals = (rowsToDelete) => {
        fetch(`${config.apiUrl}/update_gwp_totals`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({rows_to_delete: rowsToDelete}),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("Parent GWP totals updated:", data);
                triggerRefresh();
            })
            .catch((error) => {
                console.error("Error updating parent GWP totals:", error);
            });
    };

    const saveTable = () => {
        const requestData = allTableRows;

        const endpoint = "save_emissions";
        fetch(`${config.apiUrl}/${endpoint}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(requestData),
        })
            .then((response) => response.json())
            .then((data) => {
                refreshData();
                triggerRefresh();
            })
            .catch((error) => {
                console.error("Error:", error);
            });
        setDisableSave(true);
    };

    const handleFileUpload = (files) => {
        console.log("Uploaded files:", files);
        alert("Files uploaded successfully!");
    };

    return (
        <div className="button-container">
            <div className="button-container-left">
                <button onClick={addRow} disabled={!selectedRow} title="Add a new row in the table">
                    <Icon icon="ic:round-plus" style={{fontSize: "16px"}}/>
                </button>
                <button onClick={deleteSelectedRow} disabled={selectedScopes.length === 0} title="Delete selected rows"
                        className="delete-button">
                    <Icon icon="ic:round-minus" style={{fontSize: "16px"}}/>
                </button>
                {/*<button onClick={DisplayDocumentWindow} disabled={!(selectedScopes.length === 1)}*/}
                {/*        title="Add and view emission documents">*/}
                {/*    <Icon icon="mi:document" style={{fontSize: "16px"}}/>*/}
                {/*</button>*/}
            </div>
            <div className="button-container-right">
                <button onClick={saveTable} disabled={disableSave} title="Save current data in the table to database">
                    <Icon icon="mdi:floppy-disk" style={{fontSize: "16px"}}/>
                </button>
            </div>

            <DocumentUploadModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onFileUpload={handleFileUpload}
                selectedEmission={selectedScopes[0]}
            />
        </div>
    );
}

export default memo(ScopeTableButton);

import React from "react";
import {Icon} from "@iconify/react";
import "../style/pages-style/NetZero.css"

function netZeroTableHeaderComponent(props) {
    const handleButtonClick = () => {
        props.onAddButtonClick(props.field);
    };

    const handleDeleteClick = () => {
        props.onDeleteButtonClick(props.field);
    };


    return (
        <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
            <span>{props.displayName}</span>
            <button className="add-content-button"
                onClick={handleButtonClick}
            >
                <Icon icon="ic:baseline-plus" className="icon" style={{ fontSize: "18px", width: "18px", height: "18px" }}/>
            </button>
            <button className="delete-column-button"
                onClick={handleDeleteClick}
            >
                <Icon icon="ix:cancel" className="icon" style={{ fontSize: "16px", width: "16px", height: "16px" }}/>
            </button>
        </div>
    );
}

export default netZeroTableHeaderComponent;